<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
      
      <div class="p20">
        <h3 class="concertListTitle">
          {{ detailInfo.title }}
        </h3>

        <div class="concertList mt15">
          <el-row :gutter="10">
            <el-col :span="10">
              <!-- <div class="concertListState"></div> -->
              <!-- <img src="@/assets/p19.jpg"> -->
              <img :src="`${$baseUrl}${detailInfo.thumb}`" alt="" />
            </el-col>
            <el-col :span="14">
              <div class="concertListRow"><b>演出时间:</b>{{detailInfo.showtime}}</div>
              <div class="concertListRow"><b>演出场馆:</b>{{detailInfo.venue }}</div>
              <div class="concertListRow"><b>演出嘉宾:</b>{{detailInfo.actor}}</div>
              <div class="concertListRow">
                <el-row :gutter="10">
                  <el-col :span="8"><div class="concertListRow"><b>演出时长:</b>{{detailInfo.showduration}}</div></el-col>
                  <el-col :span="8"><div class="concertListRow"><b>入场时间:</b>{{detailInfo.admissiontime}}</div></el-col>
                  <el-col :span="8"><div class="concertListRow"><b>寄存说明:</b>{{detailInfo.deposit}}</div></el-col>
                </el-row>
              </div>

            </el-col>
          </el-row>
          <div class="concertListRow mt20"><b>儿童入场提示:</b>{{detailInfo.kidtips}}</div>
          <div class="concertListRow"><b>禁止携带的物品说明:</b>{{detailInfo.prohibitedtips}}</div>
          <div class="pt10">
            <a :href="detailInfo.showurl" target="__blank" class="concertListBtn" v-if="detailInfo.showstatus == 'selling'">
              {{showstatus[detailInfo.showstatus]}}
            </a>
            <button class="concertListBtn disable" v-else>
              {{showstatus[detailInfo.showstatus]}}
            </button>
          </div>

          <div class="pt20">
            <img src="@/assets/t19.png" width="294" />
          </div>
          <div style="margin-top:20px" v-html="contents">
          </div>
        </div>
      </div>
      
      <Footer />

    </div>
    
  </div>
</template>

<style scoped>
 
</style>
<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
export default {
  
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
      isIndex: false,
      joinList:[
        {
          title:"招聘岗位名称1",
          othe:"时间/地点",
          describe:"苏州民族管弦乐团是由苏州市和高新区共同成立的职业乐团，为市级公益性文艺团体。乐团由曾经十三次赴维也纳金色大厅指挥的著名指挥家彭家鹏任艺术总监兼首席指挥，由著名二胡演奏家、作曲家朱昌耀任艺术指导。聘请中国音乐家协会名誉主席、著名作曲家赵季平，中国民族管弦乐学会会长、著名作曲家刘锡津，中国民族管弦乐学会副会长兼秘书长王书伟，中国民族管弦乐学会荣誉会长、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:true
        },{
          title:"招聘岗位名称2",
          othe:"时间/地点",
          describe:"苏州民族管弦乐团是由苏州市和高新区、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:false
        },{
          title:"招聘岗位名称3",
          othe:"时间/地点",
          describe:"乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:false
        }
      ],
      detailInfo: {},
      showstatus:{
        init:'未开票',
        selling: '已开票',
        end:'已结束'
      },
      baseUrl : 'https://www.suzhousco.com'
    }
  },
  computed: {
    // state() {
    //   return this.$store.state;
    // },
    contents () {
      let content = this.detailInfo.content2 || ''
      let newStr = content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function(match, capture) {
        if(!/http|https/.test(capture)) {
          return `<img style="max-width: 100%;" src="https://www.suzhousco.com${capture}" alt="" />`
        } else {
          return '<img style="max-width: 100%;" src="'+capture+'" alt="" />';
        }
      })
      return newStr
    }
  },
  created() {
    this.id = this.$route.params.id || 0;
    this.getNewsDetail();
  },

  methods:{
    joinView(index){
      this.joinList[index].show = !this.joinList[index].show
    },
    // 获取演出列表
    getNewsDetail() {
      this.$ajax
        .get("/cms/api/showdetail", {
          id: this.id,
        })
        .then((res) => {
          if (res.code == "200") {
            // this.showlist = res.data;
            console.log("tag", res);
            this.detailInfo = res;
          }
        });
    },
    // getCompanys(){
      
    // }
    
  },
}
</script>

